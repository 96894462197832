module.exports = {
  siteMetadata:
    {
      title: `ALCHEMY DANCE`,
      subtitle: 'mit Freude tanzen',
      description: `Alchemy Dance bietet verschiedene Möglichkeiten um Körper, Geist und Seele in Einklang zu bringen.
                    Sei es mit spirituellem Tanz oder Line Dance oder vielem mehr.`,
      label:  `Tanz, orientalischer Tanz, Line Dance, Bauchtanz, spiritueller Tanz, Yoga, Lovely Lines, Tanz dich ganz, 
               Alchemy Dance, Veranstaltung, Kurs, Gruppe, Ware, Kleidung, Lernen, Wissen, Basteln, Kummer, Ballast,
               Seele, Selbstfindung, Lebenslust, Lebensqualität, Stressabbau, Work-Life-Balance, Ballett, Jazzdance,
               Musik, Tanzkurs, Entspannung, Gelassenheit, Achtsamkeit, Körperbeherrschung, Gleichgewicht, Agilität,
               Eleganz, Fleyibilität, Erwachsenenbildung, Volksbildung, Bildung, Frauenpower, Frauen 
               Shop, Seminar, Andrea Andreassen, Alex Schneider`,
      author: `Orcana01`,
    },

  plugins: [
    `gatsby-plugin-react-helmet`,
      {
        resolve: `gatsby-source-filesystem`,
        options:
          {
            name: `images`,
            path: `${__dirname}/src/images`,
          },
      },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
      {
        resolve: `gatsby-plugin-manifest`,
        options:
          {
            name: `gatsby-starter-default`,
            short_name: `starter`,
            start_url: `/`,
            background_color: `#663399`,
            theme_color: `#663399`,
            display: `minimal-ui`,
            icon: `src/images/icon.png`,
          },
      },
    ],
}
