import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import BtDancerImage from "./image_bt_dancer"
import AndreaGoldImage from "./image_andreagold"
import AlexGoldImage from "./image_alexgold"

import { Link } from "gatsby"

import "./layout.css"
import "./css/bootstrap-grid.css"
import "./css/bootstrap-reboot.css"
import "./css/bootstrap.css"

import "./ad.css"

import config from "../../gatsby-config"


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>

        <link rel="stylesheet" href="/css/bootstrap.min.css"></link>
        <link rel="stylesheet" href="/css/solid.css"></link>

        <div className="container">
          <div className="row">

            <div className="d-none d-lg-block" id="sidebar" style={{width: "262px"}}>

              <Link to="//#maintitle" title="zur Startseite" >
                <BtDancerImage alt="Logo von Alchemy Dance"/>
              </Link>

              <Link to="/ad_1_aktuelles-klartext#ueberschrift" title="Veranstaltungen &amp; mehr" className="nav-item row mainlink">Aktuelles</Link>

              <Link to="/ad_b3_construction#maintitle" title="spiritueller Tanz für Frauen" className="nav-item row mainlink">Tanz dich ganz</Link>

              <Link to="/ad_b3_construction#maintitle" title="Line Dance" className="nav-item row mainlink">Lovely Lines</Link>

              <Link to="/ad_b3_construction#maintitle" title="allgemeine Informationen zur Entstehung" className="nav-item row mainlink">Historie</Link>
              
                <div className="contactstyle">
                  <AndreaGoldImage />
                  <AlexGoldImage />
                  <p></p>
                  Copyright
                  <br />
                  ©&thinsp;2019&ensp;A.&nbsp;Andreassen<br />
                  Webseiten-Stand: 
                  <br />
                  ©&thinsp;{new Date().getFullYear()}&nbsp;&nbsp;
                </div>

            </div>

            <div className="col" style={{ textAlign: "center" }}>

              <header className="b-head">
                <img src="../images/hamburger.svg"
                  alt="show sidebar"
                  className="d-lg-none"
                  id="hamburger"
                  style={{ position: "absolute", left: "62px", top: "70px"}} />
                <div className="container">
                  <div className="row" style={{ marginTop: "2em" }}>
                    <div style={{ margin: "auto" }}>
                      <p style={{ fontWeight: "bold", marginBottom: "0.1em" }} id="maintitle">
                        {data.site.siteMetadata.title}
                      </p>
                    </div>
                  </div>
                </div>
                <p id="subtitle">{config.siteMetadata.subtitle}</p>
              </header>

              {children}

              <footer className="b-footer">
                <div className="container">
                  <div className="row"  >
                    <div style={{ margin: "auto" }}>
                      <p></p>
                      <div>
                        <Link to="/ad_b1_impressum/" title="Verantwortlichkeit und rechtliche Hinweise">Impressum</Link>
                        <span style={{ fontSize: "1.2em" }}> &amp; </span>
                        <Link to="/ad_b2_datenschutz/" title="Datenschutzerklärung und Personenbezogene Daten">Datenschutz</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>

            </div>
          </div>
        </div>

        <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossOrigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossOrigin="anonymous"></script>
        <script src="/js/bootstrap.bundle.min.js"></script>

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout